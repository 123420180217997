import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Tofu isn't just something i like to eat`}</h1>
    <p>{`OK Ecommerce project update:`}</p>
    <p>{`Originally I planned to launch a new ecommerce store from scratch and build it
to $100k/month ($1M+ per year), but we've been waiting for samples to arrive
(and dealing with all kinds of shipping delays).`}</p>
    <p>{`So in the meantime, me and Ben started helping my wife grow her ecom store:`}</p>
    <p>{`Here’s how it’s grown over the past 3 months:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "43.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB75FZEM+0f//EABkQAAMAAwAAAAAAAAAAAAAAAAECAwAQEv/aAAgBAQABBQLGuOUs5G//xAAYEQACAwAAAAAAAAAAAAAAAAAAARESUf/aAAgBAwEBPwFyVen/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAARIgMf/aAAgBAQAGPwIcdr//xAAbEAACAgMBAAAAAAAAAAAAAAAAARExECFBUf/aAAgBAQABPyHRdcV1DE3F+DvCP//aAAwDAQACAAMAAAAQGD//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/ECuDkGT/xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAECAQE/EFCrD//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExYf/aAAgBAQABPxARlhEAkpLax2ATcpnknGHWcT//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "3 month growth",
          "title": "3 month growth",
          "src": "/static/9180892964aaeaf0625a0153135ec514/04bec/3-month-growth.jpg",
          "srcSet": ["/static/9180892964aaeaf0625a0153135ec514/a6407/3-month-growth.jpg 400w", "/static/9180892964aaeaf0625a0153135ec514/a24e6/3-month-growth.jpg 800w", "/static/9180892964aaeaf0625a0153135ec514/04bec/3-month-growth.jpg 1600w", "/static/9180892964aaeaf0625a0153135ec514/369a7/3-month-growth.jpg 1736w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`And this month is on pace to be six-figures including Black Friday.`}</p>
    <p>{`ecommerce is great in this way. Once you figure out a working model, you can
scale up pretty quickly (before one of the pipes break and you gotta fix
everything all of a sudden).`}</p>
    <p>{`We’ve shared several of the tactics we’ve used so far.. But I wanted to put it
all in one place so you can see what worked.`}</p>
    <p>{`How did we grow to six figures?`}</p>
    <p>{`My wife already had the hard part done. A great product that customers love.`}</p>
    <p>{`So to grow - we focused on 3 things:`}</p>
    <ol>
      <li parentName="ol">{`Ads`}</li>
      <li parentName="ol">{`Emails`}</li>
      <li parentName="ol">{`Upsells`}</li>
    </ol>
    <p>{`Ads was a complicated one. We did several calls with friends who have been doing
Facebook ad stuff for years, and they helped us get things structured.`}</p>
    <p>{`The dashboard used to be a mess. Just random campaigns called like “conversions”
“conversions copy” “conversions copy1” “please sweet baby jesus convert
something”`}</p>
    <p>{`What we learned was to organize the ads into 3 high level buckets:`}</p>
    <ol>
      <li parentName="ol">{`TOFU - top of funnel (new customers)`}</li>
      <li parentName="ol">{`MOFU - middle of funnel (existing customers)`}</li>
      <li parentName="ol">{`BOFU - bottom of funnel (retargeting ads)`}</li>
    </ol>
    <p>{`And instead of saying generaically “oh we have a ROAS of 3.5x”, you really have
to look at each part of the funnel separately. TOFU should have a diff set of
metrics than BOFU.`}</p>
    <p>{`I asked every friend “do you have a freelancer I can hire who can setup my fb
ads system?” but it’s a tough ask. These people are worth their weight in gold.
One person referred me to a guy in India from upwork. I paid him ~`}{`$`}{`2k for a 1
month test, but he didn’t blow my socks off.`}</p>
    <p>{`So then Ben rolled up his sleeves and figured out:`}</p>
    <p>{`1 - what ads have performed best so far?`}</p>
    <p>{`2 - what audiences have performed best?`}</p>
    <p>{`3 - how do we create a new landing page that is less ‘pretty’ and more
‘effective’ at converting traffic.`}</p>
    <p>{`4 - he went through a bunch of popular DTC brands in the Facebook Ad
Library...scrolled down to the bottom to find their longest running ads...and
then made clones of those for our store.`}</p>
    <p>{`No sexy answer, just a bunch of small tests and tweaks. Once again, no silver
bullet, just a bunch of lead bullets.`}</p>
    <p>{`After about a week of testing, he was able to double the Return on Ad Spend
(ROAS). And once the ROAS went up, we upped the ad spend from
$700 a day to $1.8k/day. If the numbers hold, we’ll up it to $3k or $4k/day.`}</p>
    <p><strong parentName="p">{`Emails`}</strong></p>
    <p>{`We initially had a ~4% capture rate of emails for visitors to the website. Now
it’s ~8-9% (2x lift).`}</p>
    <p>{`We switched from Klayvio to Privy (they make popups for email capture) - and we
tried different copy & images until we got better performance. The best
performance came from a popup offering a 20% discount, with the copy:`}</p>
    <p>{`WAIT`}<br parentName="p"></br>{`
`}{`Get a 20% discount code`}<br parentName="p"></br>{`
`}{`[enter your email]`}</p>
    <p><strong parentName="p">{`Upsells`}</strong></p>
    <p>{`The store initially had an Average Order Value (AOV) of `}{`$`}{`60.`}</p>
    <p>{`So we ran an upsell saying “Get a free $25 Gift Card if you spend over $100 in
the store!”`}</p>
    <p>{`This boosted the AOV from $60 to $80 (huge) `}<strong parentName="p">{`and`}</strong>{` since you got a gift card
for `}{`$`}{`25… it’s going to drive a bunch of people back for a repeat purchase
because they want to use their gift card. (repeat purchase = key for ecom)`}</p>
    <p>{`This was a much better idea than offering 20% off of today’s purchase (lower’s
AOV, and no hook to come back to the store again).`}</p>
    <p>{`Then we created ads promoting this offer, and it started to really pop.`}</p>
    <p>{`None of this is genius. No clever tricks or brilliant moves. Just really basic
tweaking and iterating and refusing to quit until the numbers danced.`}</p>
    <p>{`I’ll be back with an update about the new ecom product sample (making the
landing page) tomorrow, and an announcement about all access pass on wednesday.`}</p>
    <p>{`-shaan`}</p>
    <PostFooter prevButton={{
      text: postMap.we_got_our_sample.label,
      slug: slugify(postMap.we_got_our_sample.slug)
    }} nextButton={{
      text: postMap.three_ecom_tactics_that_drove_30k_in_incremental_revenue.label,
      slug: slugify(postMap.three_ecom_tactics_that_drove_30k_in_incremental_revenue.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      